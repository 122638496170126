/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'browser-firefox': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M13.384 3.408c.535.276 1.22 1.152 1.556 1.963a8 8 0 01.503 3.897l-.009.077-.026.224A7.758 7.758 0 01.006 8.257v-.04q.025-.545.114-1.082c.01-.074.075-.42.09-.489l.01-.051a6.6 6.6 0 011.041-2.35q.327-.465.725-.87.35-.358.758-.65a1.5 1.5 0 01.26-.137c-.018.268-.04 1.553.268 1.943h.003a5.7 5.7 0 011.868-1.443 3.6 3.6 0 00.021 1.896q.105.07.2.152c.107.09.226.207.454.433l.068.066.009.009a2 2 0 00.213.18c.383.287.943.563 1.306.741.201.1.342.168.359.193l.004.008c-.012.193-.695.858-.933.858-2.206 0-2.564 1.335-2.564 1.335.087.997.714 1.839 1.517 2.357a4 4 0 00.439.241q.114.05.228.094c.325.115.665.18 1.01.194 3.043.143 4.155-2.804 3.129-4.745v-.001a3 3 0 00-.731-.9 3 3 0 00-.571-.37l-.003-.002a2.68 2.68 0 011.87.454 3.92 3.92 0 00-3.396-1.983q-.116.001-.23.01l-.042.003V4.31h-.002a4 4 0 00-.8.14 7 7 0 00-.333-.314 2 2 0 00-.2-.152 4 4 0 01-.088-.383 5 5 0 011.352-.289l.05-.003c.052-.004.125-.01.205-.012C7.996 2.212 8.733.843 10.17.002l-.003.005.003-.001.002-.002h.002l.002-.002h.015a.02.02 0 01.012.007 2.4 2.4 0 00.206.48q.09.153.183.297c.49.774 1.023 1.379 1.543 1.968.771.874 1.512 1.715 2.036 3.02l-.001-.013a8 8 0 00-.786-2.353"/>',
    },
});
